import React from 'react';
import {FaHome} from 'react-icons/fa';
import {Container, Item, Left, Right} from './Header.styled';

export default function Header() {
  return (
    <Container>
      <Left>
        <Item aria-label="Home page" fade={true} to="/">
          <FaHome size={24} />
        </Item>
      </Left>
      <Right>
        <Item aria-label="About page" fade={true} to="/about">
          About me
        </Item>
        <Item aria-label="Contact page" fade={true} to="/contact">
          Contact
        </Item>
      </Right>
    </Container>
  );
}
