import styled from 'styled-components';

export const Container = styled('div')`
  background: rgb(2, 0, 36);
  background: linear-gradient(
    45deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 47%,
    rgba(0, 212, 255, 1) 100%
  );
  background-position: center;
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  ${props => props.theme.mediaSmallDevice} {
    color: red;
    flex-direction: column;
  }
`;

export const ChildrenWrapper = styled('div')`
  width: 60%;
`;
