import {graphql, useStaticQuery} from 'gatsby';
import React from 'react';
import {Helmet} from 'react-helmet';

const getData = graphql`
  query {
    site {
      siteMetadata {
        siteTitle: title
        siteDescription: description
        author
        image
        siteUrl
      }
    }
  }
`;

interface Props {
  title?: string;
  description?: string;
}

export default function SEO(props: Props) {
  const {title, description} = props;

  const {site} = useStaticQuery(getData);
  const {siteTitle, siteDescription, image, siteUrl} = site.siteMetadata;

  return (
    <Helmet
      htmlAttributes={{lang: 'en'}}
      title={title ? `${title} | ${siteTitle}` : siteTitle}
    >
      <meta name="description" content={description || siteDescription} />
      <meta name="image" content={image} />
      {/* facebook info */}
      <meta name="og:url" content={siteUrl} /> add later
      <meta name="og:type" content="website" />
      <meta
        name="og:title"
        content={title ? `${title} | ${siteTitle}` : siteTitle}
      />
      <meta name="og:description" content={description || siteDescription} />
      <meta name="og:image" content={`${siteUrl}${image}`} />
      <meta name="og:image:width" content="400" />
      <meta name="og:image:height" content="300" />
      />
    </Helmet>
  );
}
