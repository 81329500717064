import React from 'react';
import {ThemeProvider} from 'styled-components';
import theme from '../../theme';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import {ChildrenWrapper, Container} from './Layout.styled';

export default function Layout({children}: {children: any}) {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Header />
        <ChildrenWrapper>{children}</ChildrenWrapper>
        <Footer />
      </Container>
    </ThemeProvider>
  );
}
