const theme = {
  /* Media */
  mediaLargeDevice: '@media (max-width: 425px)',
  mediaMediumDevice: '@media (max-width: 375px)',
  mediaSmallDevice: '@media (max-width: 360px)',
  mediaVerySmallDevice: '@media (max-width: 340px)',

  lightBlue: '#2E9CCA',
};

export type ThemeKeys = keyof typeof theme;

export default theme;
