import AniLink from 'gatsby-plugin-transition-link/AniLink';
import styled from 'styled-components';

export const Container = styled('ul')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  margin: 30px;
  list-style: none;
  font-weight: 600;
  width: 100%;
  align-self: flex-end;

  ${props => props.theme.mediaMediumDevice} {
    font-size: 14px;
    margin: 10px;
  }

  ${props => props.theme.mediaSmallDevice} {
    font-size: 12px;
    margin: 10px;
  }
`;

export const Item = styled(AniLink)`
  text-decoration: none;
  margin-left: 30px;
  color: white;
`;

export const Left = styled('div')`
  margin-left: 30px;
`;

export const Right = styled('div')``;
