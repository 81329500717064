import AniLink from 'gatsby-plugin-transition-link/AniLink';
import styled from 'styled-components';

export const Container = styled('ul')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: white;
  margin: 30px;
  list-style: none;
  align-self: flex-end;
  width: 100%;
`;

export const Item = styled('a')`
  text-decoration: none;
  margin-left: 30px;
  color: white;
`;

export const StyledLink = styled(AniLink)`
  text-decoration: none;
  margin-left: 30px;
  color: white;
`;
