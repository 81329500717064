import React from 'react';
import {FaFacebookSquare, FaLinkedinIn} from 'react-icons/fa';
import {IoIosMail} from 'react-icons/io';
import {Container, Item, StyledLink} from './Footer.styled';

const ICON_SIZE = 24;

export default function Footer() {
  return (
    <Container>
      <StyledLink aria-label="Contact page" fade={true} to="/contact">
        <IoIosMail size={ICON_SIZE} />
      </StyledLink>
      <Item
        aria-label="Facebook page"
        rel="noopener"
        target="_blank"
        href="https://www.facebook.com/bendevski"
      >
        <FaFacebookSquare size={ICON_SIZE} />
      </Item>
      <Item
        aria-label="Linkedin page"
        rel="noopener"
        target="_blank"
        href="https://mk.linkedin.com/in/ognen-bendevski-1b139086"
      >
        <FaLinkedinIn size={ICON_SIZE} />
      </Item>
    </Container>
  );
}
